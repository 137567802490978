import React, { useState, useRef } from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

const ImageCropper = ({ src, setOutput }) => {
  const [crop, setCrop] = useState();
  const imgRef = useRef(null);

  const handleComplete = (e) => {
    cropAndSetImage();
  };

  function cropAndSetImage() {
    const { x, y, width, height } = crop;
    const img = new Image();
    img.src = src;

    img.onload = () => {
      const naturalWidth = img.width;
      const naturalHeight = img.height;

      // Calculate pixel values from percentages
      const pixelWidth = (width / 100) * naturalWidth;
      const pixelHeight = (height / 100) * naturalHeight;
      const pixelX = (x / 100) * naturalWidth;
      const pixelY = (y / 100) * naturalHeight;

      // Create a canvas element
      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");

      // Set the canvas dimensions to the cropped area size
      canvas.width = pixelWidth;
      canvas.height = pixelHeight;

      // Crop the image and draw it on the canvas
      context.drawImage(
        img,
        pixelX,
        pixelY,
        pixelWidth,
        pixelHeight,
        0,
        0,
        pixelWidth,
        pixelHeight
      );

      // Convert the cropped canvas to a data URL
      const croppedImage = canvas.toDataURL();

      setOutput(croppedImage);
    };
  }

  return (
    <div style={{ position: "relative", width: "100%", maxHeight: "400px" }}>
      <ReactCrop
        crop={crop}
        onChange={(_, c) => setCrop(c)}
        onComplete={(c) => handleComplete(c)}
        style={{ maxHeight: "400px", maxWidth: "400px" }}
      >
        <span>
          {" "}
          <img className="crop-img" alt="to-crop" src={src} ref={imgRef} />
        </span>
      </ReactCrop>
    </div>
  );
};

export default ImageCropper;
