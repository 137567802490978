import React, { useState } from "react";
import Imagecard from "./imagecard";
import Crop from "./Crop";
import "../style/components.css";

const ImageCarousel = ({ images, setImages, unit, setUnit }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [cropOpen, setCropOpen] = useState(false);

  const nextImage = () => {
    // Calculate the next index
    const nextIndex = (currentIndex + 1) % images.length;

    setCurrentIndex(nextIndex);
  };

  const previousImage = () => {
    // Calculate the previous index
    const previousIndex = (currentIndex - 1 + images.length) % images.length;

    setCurrentIndex(previousIndex);
  };

  const handleCropButton = () => {
    setCropOpen(true);
  };

  const handleVolumeInput = (e) => {
    // Create a copy of the images array
    const modifiedImages = [...images];
    // Update the volume of the image at currentIndex in the copy
    modifiedImages[currentIndex].volume = e.target.value;
    // Set the updated copy as the new state
    setImages(modifiedImages);
  };

  const handleSelectChange = (e) => {
    setUnit(e.target.value);
  };

  return (
    <div className="image-slider">
      {images.length > 0 ? (
        images.map((image, index) => (
          <img
            style={{
              boxShadow: "0 0 3px 1px gray",
              border: "1px solid darkgray",
              maxHeight: "30vh",
            }}
            key={index}
            src={image.dataURL}
            alt={index}
            className={index === currentIndex ? "" : "invisible"}
          />
        ))
      ) : (
        <p>Upload Images</p>
      )}

      {images.length > 0 && (
        <div className="testing-stuff">
          <div className="volume-input-container">
            <input
              type="text"
              id="volume-input"
              name="volume-input"
              value={images[currentIndex]?.volume || ""}
              onChange={handleVolumeInput}
              className="volume-input"
              placeholder="Enter Volume"
            />
            <select
              value={unit}
              onChange={handleSelectChange}
              name="unit"
              className="unit-drop-down"
            >
              <option value="milliliters">Milliliters (mL)</option>
              <option value="liters">Liters (L)</option>
              <option value="cubic-centimeters">Cubic Centimeters (cm³)</option>
              <option value="microliters">Microliters (μL)</option>
              <option value="nanoliters">Nanoliters (nL)</option>
              <option value="pints">Pints (pt)</option>
            </select>
          </div>

          <div onClick={handleCropButton} className="crop-button-container">
            <button className="crop-button">Crop Photo</button>
          </div>
        </div>
      )}
      {images.length > 1 && (
        <div className="selection-buttons">
          <button onClick={previousImage}>Previous Image</button>
          <button onClick={nextImage}>Next Image</button>
        </div>
      )}
      {images.length > 1 && (
        <div className="counter">
          {images.length === 0 ? 0 : currentIndex + 1} / {images.length}{" "}
        </div>
      )}
      {images.map((image, index) => (
        <Imagecard
          key={index}
          image={image}
          id={index}
          images={images}
          setImages={setImages}
          currentIndex={currentIndex}
          setCurrentIndex={setCurrentIndex}
          unit={unit}
        />
      ))}
      {images.length > 0 && cropOpen && (
        <Crop
          img={images[currentIndex].dataURL}
          index={currentIndex}
          open={cropOpen}
          setOpen={setCropOpen}
          images={images}
          setImages={setImages}
        />
      )}
    </div>
  );
};

export default ImageCarousel;
