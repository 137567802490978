import React, { useState } from "react";
import JSZip from "jszip";
import Header from "../components/header.jsx";
import ImageCarousel from "../components/ImageCarousel.jsx";
import "../style/main.css";

const Main = () => {
  const [blankImage, setBlankImage] = useState([]);
  const [standardImage, setStadardImage] = useState([]);
  const [unknownImages, setUnknownImages] = useState([]);
  const [unit, setUnit] = useState("mL");
  const [graph, setGraph] = useState();
  const [csvFile, setCsvFile] = useState();

  const handleImageUpload = (event, t) => {
    const newImages = [];

    for (let i = 0; i < event.target.files.length; i++) {
      const file = event.target.files[i];

      if (file) {
        const reader = new FileReader();

        reader.onload = (e) => {
          newImages.push({
            name: file.name, // Store the file name along with the data URL
            dataURL: e.target.result,
          });

          // If we have processed all images, update the state
          if (newImages.length === event.target.files.length) {
            if (t === "normal") {
              setUnknownImages([...unknownImages, ...newImages]);
            } else if (t === "blank") {
              setBlankImage(newImages);
            } else if (t === "standard") {
              setStadardImage(newImages);
            }
          }
        };

        reader.readAsDataURL(file);
      }
    }
  };

  
  // Additional code to make the API call when the user clicks "Generate Graph" button
  // Function to make the API call to Flask
  const generateReport = async () => {
    const formData = {
      "blank-image": blankImage[0],
      "standard-image": standardImage[0],
      "mid-image": unknownImages,
      "known-concentration": standardImage[0].volume,
      unit: unit,
    };

    try {
      const response = await fetch(
        "http://api.photo-colorimetry.com/api/get-concentration",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      if (response.status === 200) {
        // Assuming the response is a ZIP archive
        const blob = await response.blob(); // Fetch the binary data

        const zip = new JSZip();

        // Load the ZIP blob
        await zip.loadAsync(blob);

        // Extract the files from the ZIP archive
        const graphFile = await zip.file("output.png").async("blob");
        const csvFile = await zip.file("output.csv").async("blob");
        // Create object URLs for the extracted files
        const graphUrl = URL.createObjectURL(graphFile);
        const csvUrl = URL.createObjectURL(csvFile);
        // Save the URLs in your state or variables
        setGraph(graphUrl);
        setCsvFile(csvUrl);
      } else {
        console.error("API call failed with status code: " + response.status);
      }
    } catch (error) {
      console.error("Error making API call:", error);
    }
  };

  // You can call this function when the user clicks the "Generate Graph" button
  const handleGenerateGraph = () => {
    // check to make sure all info is filled out
    if (!(blankImage.length && standardImage.length && unknownImages.length)) {
      alert("You need to have at least one each image type (Blank, Standard, and Unknown).")
      return;
    }
    if (!standardImage[0].volume) {
      alert("You need to enter the volume for you standard image.")
      return;
    }
    if (!unknownImages.every(item => item && item.volume)) {
      alert("You need to enter the volume for all of your unknown images.")
      return;
    }

    console.log("Safe");
    generateReport();
  };

  return (
    <main>
      <Header />
      <section className="upload-container">
        <article className="image-upload" id="blank_img">
          <h2>Blank Image</h2>
          <ImageCarousel
            images={blankImage}
            setImages={setBlankImage}
            unit={unit}
            setUnit={setUnit}
          />
          <label>
            <input
              type="file"
              accept="image/*"
              onChange={(e) => handleImageUpload(e, "blank")}
            />
            <div className="upload-text">Upload Blank Images</div>
          </label>
        </article>
        <article className="image-upload" id="standard_img">
          <h2>Standard Image</h2>

          <ImageCarousel
            images={standardImage}
            setImages={setStadardImage}
            unit={unit}
            setUnit={setUnit}
          />
          <label>
            <input
              type="file"
              accept="image/*"
              onChange={(e) => handleImageUpload(e, "standard")}
            />
            <div className="upload-text">Upload Standard Image</div>
          </label>
        </article>
        <article className="image-upload" id="normal_imgs">
          <h2>Unknown Sample Images</h2>

          <ImageCarousel
            images={unknownImages}
            setImages={setUnknownImages}
            unit={unit}
            setUnit={setUnit}
          />
          <label>
            <input
              type="file"
              accept="image/*"
              onChange={(e) => handleImageUpload(e, "normal")}
              multiple
            />
            <div className="upload-text">Upload Images</div>
          </label>
        </article>
      </section>
      <section>
        <button className="generate-button" onClick={handleGenerateGraph}>Generate Graph</button>
      </section>
      {graph && (
        <section className="results">
          <img alt="graph" src={graph} />
          <a href={csvFile} download="output.csv">
            CSV DOWNLOAD
          </a>
        </section>
      )}
    </main>
  );
};

export default Main;
