import React, { useState } from "react";
import ImageCropper from "./ImageCropper";
import "react-image-crop/dist/ReactCrop.css";
import "../style/components.css";

const Crop = ({ img, index, setOpen, images, setImages }) => {
  const [croppedImage, setCroppedImage] = useState(img);

  const handleSave = () => {
    // Update the images state with the croppedImage

    const newImage = {
      dataURL: croppedImage,
      name: images[index].name,
      volume: images[index].volume,
    };
    var updatedImages = images;
    updatedImages[index] = newImage;

    setImages(updatedImages);
    setOpen(false);
  };

  return (
    <div className="background">
      <div className="pop-up">
        <h2>Select the Section You Would Like Analyzed</h2>
        <section>
          <ImageCropper src={img} setOutput={setCroppedImage} />
        </section>
        <div>
          <button className="save-button" onClick={handleSave}>
            Save
          </button>
          {croppedImage && (
            <div>
              <h2>Cropped Image</h2>
              <span className="after-crop">
                <img
                  className="after-crop-img"
                  alt="Cropped Img"
                  src={croppedImage}
                />
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Crop;
