import React from "react";

const Imagecard = ({
  image,
  id,
  images,
  setImages,
  currentIndex,
  setCurrentIndex,
  unit,
}) => {
  const removeImage = () => {
    let temp = [...images];
    temp.splice(id, 1);
    setImages(temp);
    if (id <= currentIndex && id !== 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };
  return (
    <div className="card" style={{ backgroundColor: currentIndex === id ? 'lightgray' : 'initial' }}>
      <div>
        {id + 1} - {image.name}
      </div>
      <div>
        {image.volume && unit && (
          <div>
            {image.volume} {unit}
          </div>
        )}
      </div>
      <div onClick={removeImage} className="x-container">
        <div className="x-line" />
        <div className="x-line" />
      </div>
    </div>
  );
};

export default Imagecard;
