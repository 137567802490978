import React from "react";
import Header from "../components/header.jsx";
import "../style/home.css";
import Figure1 from "./Figure1.jpg";

const home = () => {
  return (
    <div className="page">
      <Header />
      <section className="use-it">
        <h2>Try Photo Colorimetry Yourself!</h2>
        <h2>
          <a href="/main">Launch Colorimetric Analysis</a>
        </h2>
      </section>
      <section id="about" className="introduction">
        <h1>What is Colorimetry?</h1>
        <p>
          Colorimetry is the quantitative determination of color. This
          determination can be based on a variety of measurements. Most
          commonly, color is measured in terms of the wavelength of light
          absorbed by a sample. A colored sample absorbs light of the
          complimentary color; for example, a red sample absorbs green light.
          Measurements of absorbance are performed using a UV-Visible
          spectrometer. The spectrometer emits light in the range of 200-800 nm.
          For each wavelength, the spectrometer measures the amount of light
          that is absorbed by the sample. When a colored sample is more
          concentrated, it appears more intensely colored to the naked eye, and
          Beer’s Law has quantified this relationship<sup>1</sup> and shows that
          the concentration of the chromophore (the colored component of the
          sample) is directly proportional to the amount of light absorbed by
          the sample:
          <br />
          <br />
          <span style={{ display: "block", textAlign: "center" }}>𝐴=𝜀𝑏𝐶</span>
          <br />
          Where A is the absorbance, ε is the molar absorptivity of the
          chromophore, b is the path length that the light travels through, and
          C is the concentration of the chromophore. In a typical UV-Visible
          experiment, ε and b remain constant, and thus the absorbance is
          proportional to the concentration of the chromophore.
        </p>
      </section>

      <section className="digital-colorimetry">
        <h2>Digital Colorimetry</h2>
        <p>
          In analogy to Beer’s Law, we theorized that the relationship between
          absorbance and chromophore concentration would be true in digital
          images of colored samples. In photos, color is measured in terms of a
          pixel’s Red, Green, and Blue (RGB) value. These values can range from
          0 to 255, where an RGB value of (0, 0, 0) corresponds to the color
          black, and (255, 255, 255) corresponds to pure white. Every color can
          be represented as a combination of these three values. One can imagine
          each color as an axis in a three-dimensional space, where any color
          can be represented as a distinct point in space. The application
          implemented on this webpage uses the Vector Projection (VP) Method
          <sup>2</sup> to determine the concentration of colored solutions. This
          method requires two extra measurements: one “blank” sample that has no
          color, and one “standard” sample that contains a known amount of the
          chromophore. First, the RGB values of the blank and standard samples
          are extracted from photos, and these two points are plotted in the 3D
          space described above. A vector is drawn between the two points, and
          this vector serves as the “standard” vector (Figure 1). Next, the RGB
          values of unknown samples are extracted and plotted in the 3D space. A
          vector is drawn from the blank point to the unknown sample point. This
          new vector is projected onto the standard vector, and the ratio of the
          magnitudes of the two vectors is proportional to the concentration of
          the chromophore. In this system, the angle between the two vectors can
          serve as a measure of error, where the larger the angle is, the less
          reliable the measurement becomes. The VP Method is described in detail
          in Ref. 2.
        </p>
      </section>
      <section className="figure-one">
        <img className="figure-one-img" alt="figure one" src={Figure1}></img>
        <p>
          <strong>Figure 1.</strong> Schematic representation of the VP Method
          in 3D RGB space.
        </p>
      </section>
      <section className="important-note">
        <p>
          It is important to note that the VP Method can only be used in systems
          that contain a single chromophore. Continuing the analogy to Beer’s
          Law, the VP Method is only applicable for dilute solutions. In very
          concentrated solutions, the relationship between absorbance and
          concentration is no longer linear.
        </p>
      </section>
      <section className="references">
        <h2>References</h2>
        <div>
          <ol>
            <li>
              <blockquote>
                Harris, D. C.; Lucy, C. A. Fundamentals of Spectroscopy.{" "}
                <i>In Quantitative Chemical Analysis</i>, W.H. Freeman &
                Company, 2010.
              </blockquote>
            </li>
            <br />
            <li>
              <blockquote>
                Schell, J.; McCauley, S. C.; Glaser, R. Video colorimetry of
                single-chromophore systems based on vector analysis in the 3D
                color space: Unexpected hysteresis loops in oscillating
                reactions. <i>Talanta</i>,
                <strong>2020</strong>, 220, 121303-121313.
              </blockquote>
            </li>
          </ol>
        </div>
      </section>
      <section className="contact">
        <h2>Contact Information</h2>
        <p>
          For more information, please contact{" "}
          <a href="mailto:photo-colorimetry@gmail.com">
            photo-colorimetry@gmail.com
          </a>
        </p>
      </section>
    </div>
  );
};

export default home;
