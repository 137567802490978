import React from "react";
import "../style/components.css";
const Header = () => {
  return (
    <header className="header">
      <div className="title">
        <h1 className="title-header">Photo Colorimetry</h1>
      </div>
      <div className="links">
        <nav className="nav">
          <a href="https://www.sciencedirect.com/science/article/abs/pii/S0039914020305944">Paper</a>
          <a href="/#about">About</a>
          <a href="/">Help</a>
        </nav>
      </div>
    </header>
  );
};

export default Header;
